<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Asignaciones Nacionales</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Carga Seca</li>
                  <li class="breadcrumb-item active">Asignaciones</li>
                  <li class="breadcrumb-item active">Solicitudes</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card" style="display: inherit">
              <div class="card-header">
                <div class="row">
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn bg-navy"
                        @click="getIndexNacional()"
                      >
                        <i class="fas fa-sync fa-spin"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <div class="row">
                  <div class="col-md-12">
                    <table
                      class="
                            table table-bordered table-striped table-hover table-sm
                          "
                      style="font-size: 90%"
                    >
                      <thead class="thead">
                        <tr>
                          <th class="text-center" style="width: 10%">
                            #
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              v-model="filtrosNacional.id"
                              @input="getIndexNacional()"
                            />
                          </th>
                          <th class="text-center">
                            Nombre Usuario
                            <input
                              type="text"
                              class="form-control form-control-sm p-0"
                              v-model="filtrosNacional.user"
                              @input="getIndexNacional()"
                            />
                          </th>
                          <th class="text-center">
                            Tipo solicitud
                            <select
                              v-model="filtrosNacional.tipo_solicitud"
                              class="form-control form-control-sm"
                              placeholder="Tipo Solicitud"
                              label="descripcion"
                              @change="getIndexNacional()"
                            >
                              <option value="">Seleccione...</option>
                              <option
                                v-for="estado in listasForms.tipos_solicitudes"
                                :key="estado.numeracion"
                                :value="estado.numeracion"
                              >
                                {{ estado.descripcion }}
                              </option>
                            </select>
                          </th>
                          <th class="text-center">
                            Fecha
                            <input
                              type="date"
                              class="form-control form-control-sm"
                              v-model="filtrosNacional.fecha"
                              @input="getIndexNacional()"
                            />
                          </th>
                          <th class="text-center">
                            Material Inventarios
                            <select
                              v-model="filtrosNacional.material_inventarios"
                              class="form-control form-control-sm"
                              placeholder="Material Inventarios"
                              @change="getIndexNacional()"
                            >
                              <option value="">Seleccione...</option>
                              <option value="1">SI</option>
                              <option value="2">NO</option>
                            </select>
                          </th>
                          <th class="text-center p-3">
                            <label>Estado</label>
                          </th>
                          <th class="text-center p-3">Acciones</th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="solicitud in solicitudesNacional.data"
                        :key="solicitud.id"
                      >
                        <tr>
                          <td class="text-center">
                            <b>
                              <button
                                id="botonImp"
                                type="button"
                                class="btn btn-sm btn-secondary"
                                style="cursor: pointer"
                                data-toggle="modal"
                                data-target="#modal-form-detalle-CsNacSolicitud"
                                @click="llenarModal(solicitud)"
                              >
                                {{ solicitud.id }}
                              </button></b
                            >
                          </td>
                          <td class="text-center">
                            {{ solicitud.user.name }}
                          </td>
                          <td class="text-center">
                            <span
                              class="badge"
                              :class="
                                solicitud.tipo_solicitud == 1
                                  ? 'bg-navy'
                                  : 'bg-danger'
                              "
                              >{{ solicitud.tipoSolicitud }}</span
                            >
                            <div
                              class="row justify-content-center"
                              v-if="solicitud.tipo_emergencia == 2"
                            >
                              <span class="badge bg-pink">Extemporaneo</span>
                            </div>
                          </td>
                          <td class="text-center">
                            {{ solicitud.fecha }}
                          </td>
                          <td class="text-center">
                            <span
                              class="badge"
                              :class="
                                solicitud.material_inventarios == 1
                                  ? 'badge-success'
                                  : 'badge-danger'
                              "
                            >
                              {{
                                solicitud.material_inventarios == 1
                                  ? "SI"
                                  : "NO"
                              }}
                            </span>
                          </td>
                          <td class="text-center">
                            <p
                              v-for="(servicio,
                              index) in solicitud.tipos_servicios"
                              :key="servicio.id"
                              class="p-0 m-0"
                            >
                              <span
                                class="badge"
                                :class="
                                  servicio.estado == 1
                                    ? 'badge-info'
                                    : servicio.estado == 2
                                    ? 'badge-primary'
                                    : servicio.estado == 3
                                    ? 'badge-warning'
                                    : ''
                                "
                              >
                                <div v-if="servicio.tipo_servicio == 1">
                                  {{ index + 1 }} .
                                  {{ servicio.tipoServicio }} -
                                  {{ servicio.tipo_vehiculo.nombre }} -
                                  {{ servicio.estadoServi }}
                                </div>
                                <div v-else>
                                  {{ index + 1 }} .
                                  {{ servicio.tipoServicio }} -
                                  {{ servicio.nTipoEquipo }} -
                                  {{ servicio.estadoServi }}
                                </div>
                              </span>
                            </p>
                          </td>
                          <td class="text-center">
                            <button
                              type="button"
                              class="btn btn-sm bg-navy"
                              v-if="
                                $store.getters.can(
                                  'cs.solAsignacionesNacionales.edit'
                                )
                              "
                              @click="showNacional(solicitud)"
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="float-left" v-if="solicitudesNacional.total">
                    <p>
                      Mostrando del <b>{{ solicitudesNacional.from }}</b> al
                      <b>{{ solicitudesNacional.to }}</b> de un total:
                      <b>{{ solicitudesNacional.total }}</b> Registros
                    </p>
                  </div>
                  <div class="float-left" v-else>
                    <p>
                      <span class="badge badge-danger">
                        No hay registros para mostrar
                      </span>
                    </p>
                  </div>
                  <pagination
                    :data="solicitudesNacional"
                    @pagination-change-page="getIndexNacional"
                    :limit="10"
                    align="right"
                  ></pagination>
                </div>
              </div>
            </div>
            <CsSolicitudesNacDetalle ref="csSolicitudesNacDetalle" />
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import pagination from "laravel-vue-pagination";
import CsSolicitudesNacDetalle from "../../../funcionariosFrontera/CargaSeca/nacionales/CsSolicitudesNacDetalle";
export default {
  name: "CsAsignacionIndex",
  components: {
    Loading,
    pagination,
    CsSolicitudesNacDetalle,
  },
  data() {
    return {
      cargando: false,
      solicitudesNacional: {},
      filtrosNacional: {
        estado: 2,
      },
      listasForms: {
        tipos_solicitudes: [],
      },
    };
  },
  methods: {
    async getIndexNacional(page = 1) {
      this.cargando = true;
      await axios
        .get("/api/cs/asignacionesSolicitudesNacionales?page=" + page, {
          params: this.filtrosNacional,
        })
        .then(async (response) => {
          this.solicitudesNacional = response.data;
          this.cargando = false;
        });
      this.cargando = false;
    },

    async getTipoSolicitud() {
      await axios.get("/api/lista/127").then((response) => {
        this.listasForms.tipos_solicitudes = response.data;
      });
    },

    showNacional(solicitud) {
      return this.$router.push({
        name: "/Cs/AsignacionesNacionalesForm",
        params: { accion: "Editar", data_edit: solicitud },
      });
    },

    llenarModal(solicitud) {
      if (this.$store.getters.can("cs.asignacionesNacional.index")) {
        this.$refs.csSolicitudesNacDetalle.llenar_modal(solicitud);
      }
    },
  },

  async mounted() {
    await this.getIndexNacional();
    await this.getTipoSolicitud();
  },
};
</script>
